import { css } from '@emotion/core'

export default () => css`
.articles-section {
  padding: 70px 0 10px;
}

  .articles-section.news-section {
    .articles-content-block {
      width: 30%;
    }
  }
  .articles-section .articles-section-title {
    color: #232323;
    font-family: "Switzer-Semibold", sans-serif;
    font-size: 42px;
    line-height: 52px;
  }

  .articles-section .articles-section-subtitle {
    margin-bottom: 36px;
    color: #232323;
    font-family: "Switzer-Semibold", sans-serif;
    font-size: 35px;
    line-height: 40px;
    text-align: center;
  }

  .articles-section .articles-section-description {
    margin-top: 12px;
    color: #060606;
    font-size: 14px;
    line-height: 22px;
  }

  .articles-section .articles-content-section {
    width: 100%;
    margin-top: 36px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .articles-section .articles-content-block {
    margin-bottom: 60px;
  }

  .articles-section .articles-content-image-block {
    width: 100%;
    height: 338px;
  }

  .articles-section .articles-content-title-block {
    margin-top: 24px;
    color: #121010;
    font-family: "Switzer-Semibold", sans-serif;
    font-size: 22px;
    line-height: 29px;
  }

  .articles-section .articles-content-description-block {
    margin-top: 8px;
    color: #121010;
    font-size: 16px;
    line-height: 24px;
  }

  .articles-section .articles-content-link-block {
    margin-top: 30px;
  }

  .articles-section .articles-content-link-block .articles-content-link {
    font-size: 14px;
    letter-spacing: 1.5px;  
    color: #121010;
    text-decoration: underline;
    text-transform: uppercase;
  }
  
  .articles-section .articles-content-link-block .articles-content-link:hover {
      color: #121010;
      text-decoration: none;
  }

  .articles-section-footer-row {
    margin-top: 20px;
    text-align: center;
  }

  @media (max-width: 767px) {
    .articles-section.news-section {
      .articles-content-block {
        width: 48%;
        margin-bottom: 35px;
      }
    }
    .articles-section .articles-section-title {
      font-size: 37px;
      line-height: 43px;
    }

    .articles-section .articles-content-block {
      width: calc(100% - 120px);
      margin-bottom: 0;
    }

    .articles-section .articles-content-image-block {
      height: 132px;
    }

    .articles-section .articles-content-image-block .global-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .articles-section .articles-content-title-block {
      font-size: 18px;
      line-height: 24px;
    }
    .articles-section .articles-content-description-block {
      font-size: 12px;
      line-height: 16px;
    }

    .articles-section .articles-section-footer-row {
      padding-bottom: 30px;
    }
  }
`